import React, { Component } from "react";
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Switch from '@mui/material/Switch';
import Grid from '@mui/material/Grid';
import condoService from "../services/condo.service";

export default class SubCondoCalcedit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentCond: props.item,
            fiscal_code: "", 
            denominazione: "",
            utilizzo: "",
            flag_fog:true,
            flag_dep:true,
            data_inizio: "",
            data_fine: "",
            consumo: "",
            millessimali: "",
            ncf: "",
            message: null,
            error: false,
            utilizzoSet: props.utilizzoSet,
          };
          this.handleSubmit = this.handleSubmit.bind(this);
          this.handleChange = this.handleChange.bind(this);
          this.handleChangeDI = this.handleChangeDI.bind(this);
          this.handleChangeDF = this.handleChangeDF.bind(this);
          this.handleChangeFog = this.handleChangeFog.bind(this);
          this.handleChangeDep = this.handleChangeDep.bind(this);

    }

    handleChange = (event) => {
      this.setState({
        utilizzo: event.target.value
      });
    };
    handleChangeDI = (event) => {
      this.setState({
        data_inizio: event.target.value
      });
    };
    handleChangeDF = (event) => {
      this.setState({
        data_fine: event.target.value
      });
    };
    handleChangeFog = (event) => {
      this.setState({
        flag_fog: !this.state.flag_fog
      });
    };
    handleChangeDep = (event) => {
      this.setState({
        flag_dep: !this.state.flag_dep
      });
    };

    componentDidUpdate(propsPrec) {
      if (this.props.item !== propsPrec.item) {
          this.setState({
            currentCond: this.props.item
          });
      }
    }

    handleSubmit(event) {
    
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        let condo = this.state.currentCond;
        if (condo == null) {
          return false
        } else {
            condo = JSON.parse(condo);
            condoService.insertSubCalc(condo,data.get('fiscal_code'), data.get('denominazione'), this.state.utilizzo, data.get('ncf'), this.state.data_inizio, this.state.data_fine, data.get('consumo'), this.state.flag_fog, this.state.flag_dep).then(
              (response) => {
                if (response.error) {
                  const resMessage = (response.messages) ? response.messages : (response.message.email) ? response.message.email : (response.message.password) ? response.message.password : "Errore generico";
                  this.setState({
                    error: true,
                    message: resMessage
                  });
                } else {
                  document.getElementById("create-sub-course-form").reset();
                    this.setState({
                        currentCond: false,
                        fiscal_code: "", 
                        denominazione: "",
                        telefono: "",
                        utilizzo: "",
                        flag_fog:true,
                        flag_dep:true,
                        ncf: "",
                        data_inizio: "",
                        data_fine: "",
                        consumo: "",
                        message: null,
                        error: false
                      }); 
                      this.props.handler()
                }
              }
            );
        }

      };
    

    componentDidMount() {

    }

    render() {
        return (
            <Box id="create-sub-course-form" component="form" onSubmit={this.handleSubmit} noValidate 
            sx={{
              boxShadow: 1,
              border: 0,
              borderColor: 'primary.light',
              '& .MuiDataGrid-cell:hover': {
                color: 'primary.main',
              },
              p: 1
            }}
            style={{backgroundColor:"white"}}
            >
                              {(this.state.error) ?
                <Alert severity="error">{this.state.message}</Alert>
                : ""
              }
            <Grid component="label" container alignItems="center" spacing={1}>
              <Grid item xs={12}>
                <TextField
                  margin="normal"
                  required
                  id="denominazione"
                  defaultValue={this.state.denominazione}
                  label="Denominazione"
                  name="denominazione"
                  error={this.state.error}
                  sx={{ mr: 1 }}
                  style={{ width: '35ch' }}
                />
                <Select
                  labelId="utilizzo"
                  id="utilizzo"
                  value={this.state.utilizzo}
                  label="Utilizzo"
                  sx={{ mr: 1, mt: 2 }}
                  style={{ width: '35ch' }}
                  onChange={this.handleChange}
                >
                {
                  this.state.utilizzoSet.map((element, key) => (
                    <MenuItem key={key} value={element.chiave}>{element.descrizione}</MenuItem>
                  ))
                }
                </Select>
                <TextField
                  id="data_inizio"
                  label="Inizio"
                  type="date"
                  // defaultValue="2020-01-24"
                  onChange={this.handleChangeDI}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={{ mt: 2, mr: 1 }}
                  style={{ width: '30ch' }}
                />
                <TextField
                  id="data_fine"
                  label="Fine"
                  type="date"
                  // defaultValue="2020-01-24"
                  onChange={this.handleChangeDF}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={{ mt: 2, mr: 1 }}
                  style={{ width: '30ch' }}
                />
              </Grid>
              <Grid item xs={6}>
                <br />
                <TextField
                  margin="normal"
                  id="consumo"
                  defaultValue={this.state.consumo}
                  label="Consumo"
                  name="consumo"
                  error={this.state.error}
                  sx={{ mr: 1 }}
                />
                <TextField
                  margin="normal"
                  id="millessimali"
                  defaultValue={this.state.millessimali}
                  label="Millessimali"
                  name="millessimali"
                  error={this.state.error}
                  sx={{ mr: 1 }}
                />
                <TextField
                  margin="normal"
                  required
                  id="ncf"
                  defaultValue={this.state.ncf}
                  label="NCF"
                  name="ncf"
                  error={this.state.error}
                  sx={{ mr: 1 }}
                />
              </Grid>
              <Grid item xs={6}>
                <Grid component="label" container alignItems="center" spacing={1}>
                  <Grid item xs={2}>Fognature</Grid>
                  <Grid item xs={2}>Depurazione</Grid>
                </Grid>
                <Grid component="label" container alignItems="center" spacing={1}>
                  <Grid item xs={2}>
                    <Grid component="label" container alignItems="center" spacing={1}>
                      <Grid item xs="auto">NO</Grid>
                      <Grid item xs="auto">
                        <Switch
                          checked={this.state.flag_fog}
                          onChange={this.handleChangeFog}
                          name="checkedB"
                          inputProps={{ 'aria-label': 'secondary checkbox' }}
                        />
                      </Grid>
                      <Grid item xs="auto">SI</Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={2}>
                    <Grid component="label" container alignItems="center" spacing={1}>
                      <Grid item xs="auto">NO</Grid>
                      <Grid item xs="auto">
                        <Switch
                          checked={this.state.flag_dep}
                          onChange={this.handleChangeDep}
                          name="checkedC"
                          inputProps={{ 'aria-label': 'secondary checkbox' }}
                        />
                      </Grid>
                      <Grid item xs="auto">SI</Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={2}>
                <Button
                  type="submit"
                  size="large"
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  style={{ width: '12ch' }}
                >
                  Salva
                </Button>
              </Grid>
            </Grid>
            </Box>
        );
    }
}
