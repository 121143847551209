import React, { Component } from "react";
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import condoService from "../services/condo.service";

export default class Condoedit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currendCond: false,
            cod_servizio: "",
            fiscal_code: "", 
            denominazione: "",
            comune: "",
            provincia: "",
            indirizzo: "",
            civico: "",
            message: null,
            error: false
          };
          this.handleSubmit = this.handleSubmit.bind(this);        
    }

    handleSubmit(event) {
    
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        condoService.insert(data.get('cod_servizio'), data.get('fiscal_code'), data.get('denominazione'), data.get('comune'), data.get('provincia'), data.get('indirizzo'), data.get('civico'), data.get('pec')).then(
          (response) => {
            if (response.error) {
              const resMessage = (response.messages) ? response.messages : (response.message.email) ? response.message.email : (response.message.password) ? response.message.password : "Errore generico";
              this.setState({
                error: true,
                message: resMessage
              });
            } else {
              document.getElementById("create-course-form").reset();
                this.setState({
                    currendCond: false,
                    cod_servizio: "",
                    fiscal_code: "", 
                    denominazione: "",
                    comune: "",
                    provincia: "",
                    indirizzo: "",
                    civico: "",
                    pec: "",
                    message: null,
                    error: false
                  });     
            }
            this.props.handler()
          }
        );
      };

    render() {
        return (
            <Box id="create-course-form" component="form" onSubmit={this.handleSubmit} noValidate 
            sx={{
              boxShadow: 1,
              border: 0,
              borderColor: 'primary.light',
              '& .MuiDataGrid-cell:hover': {
                color: 'primary.main',
              },
              p: 1
            }}
            style={{backgroundColor:"white"}}>
                              {(this.state.error) ?
                <Alert severity="error">{this.state.message}</Alert>
                : ""
              }
                <TextField
                margin="normal"
                required
                id="fiscal_code"
                defaultValue={this.state.fiscal_code}
                label="Partita Iva"
                name="fiscal_code"
                error={this.state.error}
                sx={{mr: 1}}
                style = {{width: '25ch'}}
                />
                <TextField
                margin="normal"
                id="cod_servizio"
                defaultValue={this.state.cod_servizio}
                label="Cod.Servizio"
                name="cod_servizio"
                error={this.state.error}
                sx={{mr: 1}}
                style = {{width: '25ch'}}
                />
                <TextField
                margin="normal"
                required
                id="denominazione"
                defaultValue={this.state.denominazione}
                label="Denominazione"
                name="denominazione"
                error={this.state.error}
                sx={{mr: 1}}
                style = {{width: '44ch'}}
                />
                <TextField
                margin="normal"
                required
                id="comune"
                defaultValue={this.state.comune}
                label="Comune"
                name="comune"
                error={this.state.error}
                sx={{mr: 1}}
                style = {{width: '38ch'}}
                />
                <TextField
                margin="normal"
                required
                id="provincia"
                defaultValue={this.state.provincia}
                label="Provincia"
                name="provincia"
                error={this.state.error}
                sx={{mr: 1}}
                style = {{width: '30ch'}}
                />
                <TextField
                margin="normal"
                required
                id="indirizzo"
                defaultValue={this.state.indirizzo}
                label="Indirizzo"
                name="indirizzo"
                error={this.state.error}
                sx={{mr: 1}}
                style = {{width: '45ch'}}
                />
                <TextField
                margin="normal"
                required
                id="civico"
                defaultValue={this.state.civico}
                label="Civico"
                name="civico"
                error={this.state.error}
                sx={{mr: 1}}
                style = {{width: '12ch'}}
                />
                <TextField
                margin="normal"
                id="pec"
                defaultValue={this.state.pec}
                label="PEC"
                name="pec"
                error={this.state.error}
                sx={{mr: 1}}
                style = {{width: '25ch'}}
                />
              <Button
                type="submit"
                size="large"
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                style = {{width: '12ch'}}
              >
                Salva
              </Button>
            </Box>
        );
    }
}
