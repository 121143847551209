import React, { Component } from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

export class CustomizedDialogs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: props.open,
            condoItem: props.condoItem
        };
    }
    componentDidUpdate(propsPrec) {
        if (this.props.open !== propsPrec.open) {
            this.setState({
                open: this.props.open,
                condoItem: this.props.condoItem
            });
        }
    }
    render() {
        return (
            <Dialog
                onClose={this.props.onClose}
                aria-labelledby="customized-dialog-title"
                open={this.state.open}
                fullWidth={true}
                maxWidth={'lg'}
                >
                <DialogTitle sx={{ m: 0, p: 2 }}>
                    <IconButton
                    aria-label="close"
                    onClick={this.props.onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                    >
                    <CloseIcon />
                    </IconButton>
                Info Calcolo Tariffa {(this.state.condoItem)? this.state.condoItem.denominazione: ""}
                </DialogTitle>
                <DialogContent dividers>
                    {(this.state.condoItem)? <>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell colSpan={2}>Descrizione</TableCell>
                                        <TableCell align="right">Importo</TableCell>
                                        <TableCell />
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell component="th" scope="row">
                                            Tariffa Fissa Depurazione
                                        </TableCell>
                                        <TableCell />
                                        <TableCell align="right">{this.state.condoItem.imp_fisso_dep}</TableCell>
                                        <TableCell />
                                    </TableRow>
                                    <TableRow>
                                        <TableCell component="th" scope="row">
                                            Tariffa Variabile Depurazione
                                        </TableCell>
                                        <TableCell />
                                        <TableCell align="right">{this.state.condoItem.imp_var_dep}</TableCell>
                                        <TableCell />
                                    </TableRow>
                                    <TableRow>
                                        <TableCell />
                                        <TableCell colSpan={2}>
                                            <b>TOTALE DEPURAZIONE</b>
                                        </TableCell>
                                        <TableCell align="right"><b>{parseFloat((+this.state.condoItem.imp_fisso_dep + +this.state.condoItem.imp_var_dep)).toFixed(2)}</b></TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell component="th" scope="row">
                                            Tariffa Fissa Fogna
                                        </TableCell>
                                        <TableCell />
                                        <TableCell align="right">{this.state.condoItem.imp_fisso_fog}</TableCell>
                                        <TableCell />
                                    </TableRow>
                                    <TableRow>
                                        <TableCell component="th" scope="row">
                                            Tariffa Variabile Fogna
                                        </TableCell>
                                        <TableCell />
                                        <TableCell align="right">{this.state.condoItem.imp_var_fog}</TableCell>
                                        <TableCell />
                                    </TableRow>
                                    <TableRow>
                                        <TableCell />
                                        <TableCell colSpan={2}>
                                            <b>TOTALE FOGNA</b>
                                        </TableCell>
                                        <TableCell align="right"><b>{parseFloat((+this.state.condoItem.imp_fisso_fog + +this.state.condoItem.imp_var_fog)).toFixed(2)}</b></TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell component="th" scope="row">
                                            Tariffa Fissa Acquedotto
                                        </TableCell>
                                        <TableCell />
                                        <TableCell align="right">{this.state.condoItem.imp_fisso_acq}</TableCell>
                                        <TableCell />
                                    </TableRow>
                                    <TableRow>
                                        <TableCell component="th" scope="row">
                                            Tariffa Variabile Acquedotto
                                        </TableCell>
                                        <TableCell />
                                        <TableCell align="right">{this.state.condoItem.imp_var_acq}</TableCell>
                                        <TableCell />
                                    </TableRow>
                                    <TableRow>
                                        <TableCell />
                                        <TableCell colSpan={2}>
                                            <b>TOTALE ACQUEDOTTO</b>
                                        </TableCell>
                                        <TableCell align="right"><b>{parseFloat((+this.state.condoItem.imp_fisso_acq + +this.state.condoItem.imp_var_acq)).toFixed(2)}</b></TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell component="th" scope="row">
                                            Oneri perequazione
                                        </TableCell>
                                        <TableCell />
                                        <TableCell align="right">{this.state.condoItem.imp_oper}</TableCell>
                                        <TableCell />
                                    </TableRow>
                                    <TableRow>
                                        <TableCell />
                                        <TableCell colSpan={2}>
                                            <b>IMPORTO IVA ESCLUSA</b>
                                        </TableCell>
                                        <TableCell align="right"><b>{parseFloat(this.state.condoItem.imp_tot_es).toFixed(2)}</b></TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell />
                                        <TableCell colSpan={2}>
                                            IVA 10%
                                        </TableCell>
                                        <TableCell align="right">{this.state.condoItem.imp_iva}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell />
                                        <TableCell colSpan={2}>
                                            Compensazione
                                        </TableCell>
                                        <TableCell align="right">{this.state.condoItem.imp_comp}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell />
                                        <TableCell colSpan={2}>
                                            <b>IMPORTO TOTALE</b>
                                        </TableCell>
                                        <TableCell align="right"><b>{this.state.condoItem.importo}</b></TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </>
                    : ""}
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={this.props.onClose}>
                        Chiudi
                    </Button>
                </DialogActions>
            </Dialog>
            );
    }
}
export default CustomizedDialogs
