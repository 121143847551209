import React, { Component } from "react";

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Stack from '@mui/material/Stack';

import Condoedit from "./condoedit.component";
import Condolist from "./condolist.component";
import SubCondo from "./subcalc.coponent";

import { findArrayElementById } from "../services/function-helper";
import condoService from "../services/condo.service";

export default class Condo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentCond: props.currentCond,
            condoList: props.condoList,
            pageType: props.pageType,
            utilizzoSet: props.utilizzoSet,
            showNC: false,
            showLM: false,
          };
          this.showNC = this.showNC.bind(this);
          this.closeNC = this.closeNC.bind(this);

          this.handlerClose = this.handlerClose.bind(this)
          this.handlerCond = this.handlerCond.bind(this)
          this.handler = this.handler.bind(this)
    }

    handler() {
        condoService.get().then(
            (responseCond) => {
                this.setState({
                    condoList: responseCond
                });
                this.closeNC()
            });
    }


    handlerCond(cond) {
        if(this.state.currentCond){
            if (cond === this.state.currentCond.id) {
                return false;
            }
        }
        condoService.getSub(cond).then(
            (responseCond) => {

                let condoEl = findArrayElementById(this.state.condoList, cond);

                this.setState({
                    currentCond: condoEl,
                    condoSubList: responseCond,
                    showLM: true
                });
            });
    }

    handlerClose(){
        this.setState({
            currentCond: null,
            condoSubList: [],
            showLM: false
        });
    }

    showNC() {
        this.setState({
            showNC: true
        });
    }

    closeNC() {
        this.setState({
            showNC: false
        });
    }

    componentDidUpdate(propsPrec) {
        if (this.props.condoList !== propsPrec.condoList) {
            this.setState({
                condoList: this.props.condoList
            });
        }
        if (this.props.utilizzoSet !== propsPrec.utilizzoSet) {
            this.setState({
                utilizzoSet: this.props.utilizzoSet
            });
        }
    }
    render() {
        return (
            <div>
                <Typography component="h2" variant="h5" sx={{ mt: 1 }}>
                    Condomini in gestione
                </Typography>
                <Condolist rows={this.state.condoList} handlerCond={this.handlerCond}/>
                {(this.state.showNC) ?
                    <div>
                        <Typography component="h2" variant="h5" sx={{ mt: 3 }}>
                            Nuovo Condominio
                            <IconButton aria-label="delete" onClick={this.closeNC}>
                                <CloseIcon fontSize="small" />
                            </IconButton>
                        </Typography>
                        <Condoedit handler={this.handler}/>
                    </div>
                    :
                    <Box sx={{ mt: 2 }}>
                        <Stack direction="row" justifyContent="end">
                            <Button variant="contained" color="primary" size="large" float="right" sx={{ mr: 1 }}
                                endIcon={<AddIcon />}
                                onClick={this.showNC}
                            >
                                Nuovo Condominio
                            </Button>
                            {(this.state.pageType === "simple")?
                            ""
                            :
                            <Button variant="contained" color="primary" size="large" float="right" component="label"
                            endIcon={<AddIcon />}
                            >
                                Importazione Massiva
                                <input
                                    type="file"
                                    hidden
                                />
                            </Button>
                            }
                        </Stack>
                    </Box>
                }
                {(!this.state.showLM) ?
                    ""
                    :
                <SubCondo condoSubList={this.state.condoSubList} currentCond={this.state.currentCond} utilizzoSet={this.state.utilizzoSet} handlerClose={this.handlerClose} handlerRefresh={this.handler} pageType={this.state.pageType}/>
                }
            </div>
        )}
}