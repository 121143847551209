import React, { Component } from "react";
import { useNavigate } from "react-router-dom";

import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';

import AuthService from "../services/auth.service";
import condoService from "../services/condo.service";

import SubCalc from "./subcalc.coponent";

export class Calculation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentUser: false,
            currentCond: {id:99},
            condoList: [],
            condoSubList: [],
            showLM: false,
            showNM: false,
            showCT: false,
        };
    }

    componentDidMount() {
        let user = localStorage.getItem('user');
        if (user == null) { } else {
            AuthService.getCurrentUser().then(
                (response) => {
                    if (response) {
                        condoService.getSub(this.state.currentCond.id).then(
                            (responseCond) => {                
                                this.setState({
                                    currentUser: response.data,
                                    condoSubList: responseCond,
                                    showLM: true
                                });
                            });
                    } else { }
                }
            );
        }
    }

    render() {
        return (
                <Container component="main" maxWidth="xl" >
                    <CssBaseline />
                    <Box
                        sx={{
                            marginTop: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                    </Box>
                    <Box
                        sx={{
                            marginTop: 1,
                            paddingBottom: 5,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'left',
                        }}
                        >
                    
                    <SubCalc condoSubList={this.state.condoSubList} currentCond={this.state.currentCond} />
                    </Box> 
                </Container>
        );
    }
}

function WithNavigate(props) {
    let navigate = useNavigate();
    return <Calculation {...props} navigate={navigate} />
}

export default WithNavigate