// export const API_URL = "http://localhost:8080/api/";
export const API_URL = "https://api.multidev.chogolisa.it/api/";


export function authHeader() {
  let user = localStorage.getItem('user');
 user = JSON.parse(localStorage.getItem('user'));
  if (user && user.accessToken) {
    return {
      Authorization: user.accessToken
    };
  } else {
    return {};
  }
}