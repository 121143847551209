import React, { Component } from "react";
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
import CancelIcon from '@mui/icons-material/Close';
import {
  useGridApiRef,
  DataGrid,
} from '@mui/x-data-grid';

import DoneIcon from '@mui/icons-material/Done';
import Typography from '@mui/material/Typography';
import condoService from "../services/condo.service";
import Tooltip from '@mui/material/Tooltip';

function getIdrico(params) {
  if(params.row.verify){
    return( 
    <div >
        <IconButton
          color="primary"
          size="small"
          aria-label="verificato"
        >
          <DoneIcon fontSize="medium" />
        </IconButton>
      </div>
    )
  }
}

function getModuli(params) {
  if(params.row.verify){
    return( 
      <div>
        <Typography variant="body1" color="black" style={{display: 'inline-block'}}>
          {(params.row.moduli)? params.row.moduli : "?"}
        </Typography>{" "}
        {(params.row.moduli !== params.row.idr_moduli)? 
        <Typography variant="body2" color="red" style={{display: 'inline-block'}}>
          ({params.row.idr_moduli})
        </Typography> : 
        <Typography variant="body2" color="green" style={{display: 'inline-block'}}>
          ({params.row.idr_moduli})
        </Typography>
        }
    </div>
    )
  }else{
    return(
    <Typography variant="body1" color="black" style={{display: 'inline-block'}}>
      {(params.row.moduli)? params.row.moduli : "?"}
    </Typography>
    )
  }
}

function getNCF(params) {
  if(params.row.verify){
    return( 
      <div>
        <Typography variant="body1" color="black" style={{display: 'inline-block'}}>
          {(params.row.ncf)? params.row.ncf : "?"}
        </Typography>{" "}
        {(params.row.ncf !== params.row.idr_ncf)? 
        <Typography variant="body2" color="red" style={{display: 'inline-block'}}>
          ({params.row.idr_ncf})
        </Typography> : 
        <Typography variant="body2" color="green" style={{display: 'inline-block'}}>
          ({params.row.idr_ncf})
        </Typography>
        }
      </div>
    )
  }else{
    return(
    <Typography variant="body1" color="black" style={{display: 'inline-block'}}>
      {(params.row.ncf)? params.row.ncf : "?"}
    </Typography>
    )
  }
}

function showImp(params) {
  if(params.row.importo !== "0"){
    return( 
        <Typography variant="body1" color="black" >
          {params.row.importo} €
        </Typography>
      )
  }else{
    return(
    <Typography variant="body1" color="black" >
      N/D
    </Typography>
    )
  }
}



function UseGridApiRefPers () {
  return useGridApiRef();
}
export default class Condolist extends Component{
  constructor(props) {
    super(props);
    this.state = {
      rows: props.rows,
      };
      // this.handleSubmit = this.handleSubmit.bind(this);        
      const RowMenuCell = {}
      RowMenuCell.propTypes = {
        api: PropTypes.object.isRequired,
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      };
      this.RowMenuCell = this.RowMenuCell.bind(this);        
    } 

  

  RowMenuCell(props) {
    const { api, id } = props;
    const classes = {
        root: {
          display: 'inline-flex',
          alignItems: 'center',
          gap: 1,
          color: 'red',
        },
        textPrimary: 'black'
      }
    const isInEditMode = api.getRowMode(id) === 'edit';
  
    const handleOpenClick = (event) => {
      event.stopPropagation();
      this.props.handlerCond(id)  
    }
  
    const handleEditClick = (event) => {
      event.stopPropagation();
      api.setRowMode(id, 'edit');
    };
  
    const handleSaveClick = (event) => {
      event.stopPropagation();
      api.commitRowChange(id);
      api.setRowMode(id, 'view');
  
      const row = api.getRow(id);
      api.updateRows([{ ...row, isNew: false }]);
    };
  
    const handleDeleteClick = (event) => {
      event.stopPropagation();
      condoService.deleteCond(id).then(
        (response) => {
          if (response.error) {
          } else {
            api.updateRows([{ id, _action: 'delete' }]);
          }
        }
      );
    };
  
    const handleCancelClick = (event) => {
      event.stopPropagation();
      api.setRowMode(id, 'view');
  
      const row = api.getRow(id);
      if (row.isNew) {
        api.updateRows([{ id, _action: 'delete' }]);
      }
    };
  
    if (isInEditMode) {
      return (
        <div className={classes.root}>
          <IconButton
            color="primary"
            size="small"
            aria-label="save"
            onClick={handleSaveClick}
          >
            <SaveIcon fontSize="small" />
          </IconButton>
          <IconButton
            color="inherit"
            size="small"
            aria-label="cancel"
            className={classes.textPrimary}
            onClick={handleCancelClick}
          >
            <CancelIcon fontSize="small" />
          </IconButton>
        </div>
      );
    }
  
    return (
      <div className={classes.root}>
        <Tooltip title="Dettagli condominio">
          <IconButton
            color="inherit"
            className={classes.textPrimary}
            size="small"
            aria-label="moduli"
            onClick={handleOpenClick}
          >
            <AutoAwesomeMotionIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Modifica Condominio">
          <IconButton
            color="inherit"
            size="small"
            aria-label="edit"
            onClick={handleEditClick}
          >
            <EditIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Cancella Condominio">
          <IconButton
            color="inherit"
            size="small"
            aria-label="delete"
            onClick={handleDeleteClick}
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </div>
    );
  }
  componentDidUpdate(propsPrec) {
    if (this.props.rows !== propsPrec.rows) {
        this.setState({
          rows: this.props.rows
        });
    }
  }

    render() {
      const apiRef = UseGridApiRefPers
      const handleRowEditStart = (params, event) => {
        event.defaultMuiPrevented = true;
    };

    const handleRowEditStop = (params, event) => {
        event.defaultMuiPrevented = true;
    };
    const columns = [
      { field: 'fiscal_code', headerName: 'Partita Iva', flex:1, editable: true },
      { field: 'cod_servizio', headerName: 'Cod. Servizio', flex:1, editable: true },
      { field: 'denominazione', headerName: 'Condominio',flex:1, editable: true },
      { field: 'comune', headerName: 'Comune',flex:1, editable: true },
      { field: 'provincia', headerName: 'Provincia',flex:1, editable: true },
      { field: 'indirizzo', headerName: 'Indirizzo', flex:1,editable: true },
      { field: 'civico', headerName: 'Civico', flex:1,editable: true },
      { field: 'pec', headerName: 'Pec',flex:1, editable: true },
      { field: 'moduli', headerName: 'Moduli', flex:1,editable: false, renderCell:getModuli},
      { field: 'ncf', headerName: 'NCF',flex:1,editable: false, renderCell:getNCF},
      { field: 'importo', headerName: 'Importo', flex:1,editable: false, renderCell:showImp},
      {
        field: 'idrico',
        headerName: 'Base idrica',
        flex:1,
        textAlign:'center',
        // valueGetter: getIdrico,
        renderCell:getIdrico
      },
      {
        field: 'actions',
        headerName: 'Azioni',
        renderCell: this.RowMenuCell,
        sortable: false,
        flex:1,
        headerAlign: 'center',
        filterable: false,
        align: 'center',
        disableColumnMenu: true,
        disableReorder: true,
      },
    ];
  return (
      <div>
        <div style={{ height: 300, width: '100%', backgroundColor: "white" }} >
            <DataGrid
                rows={this.state.rows}
                columns={columns}
                apiRef={apiRef}
                editMode="row"
                sx={{
                  boxShadow: 1,
                  border: 0,
                  borderColor: 'primary.light',
                  '& .MuiDataGrid-cell:hover': {
                    color: 'primary.main',
                  },
                }}
                onRowEditStart={handleRowEditStart}
                onRowEditStop={handleRowEditStop}
                componentsProps={{
                toolbar: { apiRef }
                }}
            />

        </div>
      </div>
  )
}
}