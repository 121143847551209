import React, { Component } from "react";
import { useNavigate } from "react-router-dom";

import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import { Settingaccordion } from "./accordion/accordion.component";
import AuthService from "../services/auth.service";
import Grid from '@mui/material/Grid';
import TariffaService from "../services/tariffa.service";

export class TariffeSet extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentUser: false,
            tariffe: []
        };
    }

    componentDidMount() {
        let user = localStorage.getItem('user');
        if (user == null) { } else {
            AuthService.getCurrentUser().then(
                (response) => {
                    if (response) {
                        TariffaService.get().then(
                            (responseCond) => {
                                this.setState({
                                    tariffe: responseCond,
                                    currentUser: response.data
                                });
                            });
                    } else { }
                }
            );
        }
    }

    render() {
        return (
                <Container component="main" maxWidth="xl" >
                    <CssBaseline />
                    <Grid container spacing={3}>
                    {
                        this.state.tariffe.map((element, key) => (
                            <Grid item xs={12} key={key}>
                                <Settingaccordion item={element}/>
                            </Grid>
                        ))
                    }
                    </Grid>  
                </Container>
        );
    }
}

function WithNavigate(props) {
    let navigate = useNavigate();
    return <TariffeSet {...props} navigate={navigate} />
}

export default WithNavigate