import React, { Component } from "react";
import { Routes, Route } from "react-router-dom";

import AppSideBar from "./components/appsidebar.component";
import Login from './components/login.component';
import Profile from './components/profile.component';
import ProfileSimple from "./components/profile-simple.component";
import Calculation from './components/calculation.component';
import AuthService from "./services/auth.service";
import TariffeSet from "./components/tariffesett.component";

import './App.css';

class App extends Component {
  constructor(props) {
    super(props);
    this.logOut = this.logOut.bind(this);

    this.state = {
      showModeratorBoard: false,
      showAdminBoard: false,
      currentUser: undefined,
    };
  }
  componentDidMount() {
    const user = AuthService.getCurrentUser();

    if (user) {
      this.setState({
        currentUser: user,
      });
    }
  }
  logOut() {
    AuthService.logout();
  }
  render() {
    return (
      <div>
        <div className="container mt-3">
        <Routes>            
            <Route exact path="/" element={<AppSideBar element={<Login />} loggedIn={false}/>} />
            <Route exact path="/profile" element={<AppSideBar element={<Profile />} loggedIn={true}/>} />
            <Route exact path="/profile-simple" element={<AppSideBar element={<ProfileSimple />} loggedIn={true}/>} />
            <Route exact path="/calculation" element={<AppSideBar element={<Calculation />} loggedIn={true}/>} />
            <Route exact path="/settings" element={<AppSideBar element={<TariffeSet />} loggedIn={true}/>} />
            <Route exact path="login/" element={<AppSideBar element={<Login />} loggedIn={false}/>}/>
          </Routes>
        </div>
      </div>
    );
  }
}

export default App;
