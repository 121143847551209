import React, { Component } from "react";
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import condoService from "../services/condo.service";

export default class Tariffa extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentCond: props.currentCond,
            importo: "",
            message: null,
            consumiReali: false,
            error: false
          };
          this.handleSubmit = this.handleSubmit.bind(this);
          this.handleChange = this.handleChange.bind(this);
    }

    componentDidUpdate(propsPrec) {
      if (this.props.currentCond !== propsPrec.currentCond) {
          this.setState({
            currentCond: this.props.currentCond
          });
      }
    }

    handleSubmit(event) {
    
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        let condo = this.state.currentCond;
        if (condo == null) {
          return false
        } else {
            condo = JSON.parse(condo);
            if(this.props.subCalc){
              condoService.calcolaTariffeCalc(condo,data.get('importo')).then(
                (response) => {
                  if (response.error) {
                    const resMessage = (response.messages) ? response.messages : (response.message.email) ? response.message.email : (response.message.password) ? response.message.password : "Errore generico";
                    this.setState({
                      error: true,
                      message: resMessage
                    });
                  } else {
                      this.setState({
                          currentCond: false,
                          importo: "",
                          message: null,
                          error: false
                        }); 
                        this.props.handler()
                        this.props.handlerRefresh()  
                  }
                }
              );
            }else{
              condoService.calcolaTariffe(condo,data.get('importo')).then(
                (response) => {
                  if (response.error) {
                    const resMessage = (response.messages) ? response.messages : (response.message.email) ? response.message.email : (response.message.password) ? response.message.password : "Errore generico";
                    this.setState({
                      error: true,
                      message: resMessage
                    });
                  } else {
                      this.setState({
                          currentCond: false,
                          importo: "",
                          message: null,
                          error: false
                        }); 
                        this.props.handler()
                        this.props.handlerRefresh()  
                  }
                }
              );
            }

        }

      };
    
    handleChange(){
      this.setState({
        consumiReali: !this.state.consumiReali
      })
    }

    render() {
        return (
            <Box component="form" onSubmit={this.handleSubmit} noValidate 
            sx={{mt:-2}}
            >
              <Typography component="div" sx={{mt:1}}>
                <Grid component="label" container alignItems="center" spacing={1}>
                  <Grid item>Millesimali</Grid>
                  <Grid item>
                    <Switch
                      checked={this.state.consumiReali}
                      onChange={this.handleChange}
                      name="checkedA"
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />
                  </Grid>
                  <Grid item>Consumi</Grid>
                </Grid>
              </Typography>
                <TextField
                margin="normal"
                required
                type="valute"
                id="importo"
                defaultValue={this.state.importo}
                label="Importo"
                name="importo"
                error={this.state.error}
                sx={{mr: 1}}
                style = {{width: '30ch'}}
                />
              {(this.state.consumiReali)?
                  <>
                  <TextField
                    id="date_inizio"
                    label="Inizio"
                    type="date"
                    // defaultValue="2020-01-24"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{mt: 2,mr: 1}}
                    style = {{width: '30ch'}}
                  />
                  <TextField
                    id="date_fine"
                    label="fine"
                    type="date"
                    // defaultValue="2020-02-24"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{mt: 2,mr: 1}}
                    style = {{width: '30ch'}}
                  />
                  </>
                :
                <></>
              }

              <Button
                type="submit"
                size="large"
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                style = {{width: '12ch'}}
              >
                Calcola
              </Button>
              {(this.state.error) ?
                <Alert severity="error">{this.state.message}</Alert>
                : ""
              }
            </Box>
        );
    }
}
