import React, { Component } from "react";
import { useNavigate  } from "react-router-dom";
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Alert from '@mui/material/Alert';

import AuthService from "../services/auth.service";


export class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      loading: false,
      message: "",
      error: false
    };
    this.handleSubmit = this.handleSubmit.bind(this);

  }

  handleSubmit(event) {
    
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    AuthService.login(data.get('email'), data.get('password')).then(
      (response) => {
        if (response.error) {
          const resMessage = (response.messages) ? response.messages : (response.message.email) ? response.message.email : (response.message.password) ? response.message.password : "Errore generico";
          this.setState({
            error: true,
            message: resMessage
          });
        } else {
          this.props.navigate("/profile");         
        }
      }
    );
  };



  render() {
    return (
      <>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              boxShadow: 1,
              border: 0,
              borderColor: 'primary.light',
              '& .MuiDataGrid-cell:hover': {
                color: 'primary.main',
              },
            }}
            style={{backgroundColor:"white"}}
          >
                <Grid container style={{background: '#f8f8f8', color: "#393244",}} sx={{p:2}}>
        <Grid item xs={12} alignItems="center" textAlign={"left"}>
          <div>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            </div>
            </Grid>
            </Grid>
            <Box component="form" onSubmit={this.handleSubmit} noValidate sx={{ p:2 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                error={this.state.error}
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                error={this.state.error}
                autoComplete="current-password"
              />
              {(this.state.error) ?
                <Alert severity="error">{this.state.message}</Alert>
                : ""
              }

              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Login
              </Button>
              <Grid container>
                <Grid item>
                  <Link href="#" variant="body2">
                    {"Non hai un account? Registrati!"}
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Container>
      </>
    );
  }

}

function WithNavigate(props) {
  let navigate = useNavigate();
  return <Login {...props} navigate={navigate} />
}

export default WithNavigate