import React, { Component } from "react";
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import CalculateIcon from '@mui/icons-material/Calculate';
import InfoIcon from '@mui/icons-material/Info';
import CustomizedDialogs from "./infodial.component";

import {
  useGridApiRef,
  DataGrid,
} from '@mui/x-data-grid';
import Grid from '@mui/material/Grid';

import Typography from '@mui/material/Typography';
import condoService from "../services/condo.service";
import LoopIcon from '@mui/icons-material/Loop';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import Tooltip from '@mui/material/Tooltip';

function ShowSend(params) {
  const classes = {
    root: {
      display: 'inline-flex',
      alignItems: 'center',
      gap: 1,
      color: 'red',
    },
    textPrimary: 'black',
  };

  if (params.row.send === "sending") {
    return (
      <div className={classes.root}>
        <IconButton
          color="primary"
          size="small"
          aria-label="save"
        >
          <LoopIcon fontSize="small" style={{animation: "spin 4s linear infinite"}}  />
          <style>{`
            @keyframes spin {
                 0% { transform: rotate(360deg); }
                 100% { transform: rotate(0deg); }
            }
        `}</style>
        </IconButton>
      </div>
    )
  } else if (params.row.send === "send") {
    return (

      <div className={classes.root}>

        <IconButton
          color="success"
          size="small"
          aria-label="save"
        >
          <MarkEmailReadIcon fontSize="small" />
        </IconButton>
      </div>
    )
  } else if (params.row.send === "error") {
    return (
      <div className={classes.root}>

        <IconButton
          color="error"
          size="small"
          aria-label="save"
        >
          <ReportGmailerrorredIcon fontSize="small" />
        </IconButton>
      </div>
    )
  } else {
    return (
      <div className={classes.root}>

        <IconButton
          color="inherit"
          size="small"
          aria-label="save"
        >
          <ForwardToInboxIcon fontSize="small" />
        </IconButton>
      </div>
    )
  }
}

function showServizi(params) {
  let $tmp_f = (params.row.flag_fog === "1") ? "SI" : "NO";
  let $tmp_d = (params.row.flag_dep === "1") ? "SI" : "NO";
  return (
    <Typography variant="caption" color="black" >
      <b>Fogna: </b>{$tmp_f} <br />
      <b>Depurazione: </b>{$tmp_d}
    </Typography>
  )
}

function showImp(params) {
  if(params.row.importo !== "0"){
    return( 
      <Grid container spacing={0}>
        <Grid item xs={12} key={1}>
          <Typography variant="body1" color="black" >
            <b>Totale: </b>{params.row.importo} €
          </Typography></Grid>
        <Grid item xs={12} key={2}>
          <Typography variant="body2" color="black" >
            <b>T.Fiss.: </b>{params.row.imp_fisso} €<br />
            <b>T.Var.: </b>{params.row.imp_var} €<br />
            {(params.row.imp_comp)?
            <>
            <b>Comp.: </b>{params.row.imp_comp} €<br />
            </>
            :
            ""
            }
          </Typography></Grid>
      </Grid>
      )
  }else{
    return(
    <Typography variant="body1" color="black" >
      N/D
    </Typography>
    )
  }
}

function showDate(params) {
  if(params.row.data_inizio){
    return( 
      <Typography variant="body1" color="black" >
        <b>Inizio: </b>{params.row.data_inizio} <br/>
        <b>FIne: </b>{params.row.data_fine} 
      </Typography>
    )
  }
}


function UseGridApiRefPers () {
  return useGridApiRef();
}

export default class SubCondoCalclist extends Component{
  constructor(props) {
    super(props);
    this.state = {
      rows: props.rows,
      dialogOpen: false,
      dialogRow: null
      };
      // this.handleSubmit = this.handleSubmit.bind(this);        
      const RowMenuCell = {}
      RowMenuCell.propTypes = {
        api: PropTypes.object.isRequired,
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      };
      this.RowMenuCell = this.RowMenuCell.bind(this);
      this.handleClickOpen = this.handleClickOpen.bind(this);
      this.handleClose = this.handleClose.bind(this);        
    }

    handleClickOpen = (row) => {
      this.setState({
        dialogOpen:true,
        dialogRow:row
      })
    };
    handleClose = () => {
      this.setState({
        dialogOpen:false,
        dialogRow: null
      })
    };
    
    RowMenuCell(props) {
      const { api, id } = props;
      const classes = {
        root: {
          display: 'inline-flex',
          alignItems: 'center',
          gap: 1,
          color: 'red',
        },
        textPrimary: 'black'
      };
      const isInEditMode = api.getRowMode(id) === 'edit';

      const handleInfoClick = (event) => {
        event.stopPropagation();
        const row = api.getRow(id);
        this.handleClickOpen(row);
      };

      const handleCalcClick = (event) => {
        event.stopPropagation();
        const row = api.getRow(id);
        condoService.calcSingSub(row).then(
          (response) => {
            if (response.error) {
            } else {
              api.updateRows([{ ...response}]);
            }
          }
        );
      };

      const handleEditClick = (event) => {
        event.stopPropagation();
        api.setRowMode(id, 'edit');
      };
    
      const handleSaveClick = (event) => {
        event.stopPropagation();
        api.commitRowChange(id);
        api.setRowMode(id, 'view');

        const row = api.getRow(id);
        condoService.editSub(row).then(
          (response) => {
            if (response.error) {
            } else {
              api.updateRows([{ ...row, isNew: false }]);
              this.props.handler()
            }
          }
        );
      };
    
      const handleDeleteClick = (event) => {
        event.stopPropagation();
        condoService.deleteSub(id).then(
          (response) => {
            if (response.error) {
            } else {
              api.updateRows([{ id, _action: 'delete' }]);
              this.props.handler()
            }
          }
        );
      };
    
      const handleCancelClick = (event) => {
        event.stopPropagation();
        api.setRowMode(id, 'view');
    
        const row = api.getRow(id);
        if (row.isNew) {
          api.updateRows([{ id, _action: 'delete' }]);
        }
      };
    
      if (isInEditMode) {
        return (
          <div className={classes.root}>
            <IconButton
              color="primary"
              size="small"
              aria-label="save"
              onClick={handleSaveClick}
            >
              <SaveIcon fontSize="small" />
            </IconButton>
            <IconButton
              color="inherit"
              size="small"
              aria-label="cancel"
              className={classes.textPrimary}
              onClick={handleCancelClick}
            >
              <CancelIcon fontSize="small" />
            </IconButton>
          </div>
        );
      }
    
      return (
        <div className={classes.root}>
          <Tooltip title="Dettagli calcolo tariffa">
            <IconButton
              color="inherit"
              size="small"
              aria-label="calc"
              onClick={handleInfoClick}
            >
              <InfoIcon fontSize="small" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Esegui calcolo per singola abitazione">
            <IconButton
              color="inherit"
              size="small"
              aria-label="calc"
              onClick={handleCalcClick}
            >
              <CalculateIcon fontSize="small" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Modifica">
            <IconButton
              color="inherit"
              size="small"
              aria-label="edit"
              onClick={handleEditClick}
            >
              <EditIcon fontSize="small" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Cancella">
            <IconButton
              color="inherit"
              size="small"
              aria-label="delete"
              onClick={handleDeleteClick}
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </div>
      );
    }

    componentDidUpdate(propsPrec) {
      if (this.props.rows !== propsPrec.rows) {
          this.setState({
            rows: this.props.rows
          });
      }
    }

    render() {
      const apiRef = UseGridApiRefPers
      const handleRowEditStart = (params, event) => {
        event.defaultMuiPrevented = true;
    };

    const handleRowEditStop = (params, event) => {
        event.defaultMuiPrevented = true;
    };

    const columns = [
      // { field: 'fiscal_code', headerName: 'Codice fiscale', flex:1,  editable: true },
      { field: 'denominazione', headerName: 'Denominazione',flex:0.5, editable: true },
      // { field: 'telefono', headerName: 'Telefono',flex:0.5, editable: true },
      { field: 'utilizzo', headerName: 'Utilizzo',flex:0.4, editable: true },
      { field: 'flag_fog', headerName: 'Servizi',flex:0.3, renderCell:showServizi},
      { field: 'ncf', headerName: 'NCF',flex:0.05, editable: true },
      { field: 'data_inizio', headerName: 'Date',flex:0.4, renderCell:showDate},
      { field: 'gg', headerName: 'GG',flex:0.1, editable: true },
      { field: 'consumo', headerName: 'Co',flex:0.1, editable: true },
      { field: 'importo', headerName: 'Importo', flex:0.4,editable: false, renderCell:showImp},
      { field: 'send', headerName: 'Invio', flex:0.1,editable: false, renderCell:ShowSend},
      {
        field: 'actions',
        headerName: 'Azioni',
        renderCell: this.RowMenuCell,
        sortable: false,
        flex:0.5,
        headerAlign: 'center',
        filterable: false,
        align: 'center',
        disableColumnMenu: true,
        disableReorder: true,
      },
    ];

  return (
    <div style={{ height: 500, width: '100%' , backgroundColor: "white" }}>
      <DataGrid
        rows={this.state.rows}
        rowHeight={105}
        columns={columns}
        apiRef={apiRef}
        editMode="row"
        sx={{
          boxShadow: 1,
          border: 0,
          borderColor: 'primary.light',
          '& .MuiDataGrid-cell:hover': {
            color: 'primary.main',
          },
        }}
        onRowEditStart={handleRowEditStart}
        onRowEditStop={handleRowEditStop}
        componentsProps={{
          toolbar: { apiRef },
        }}
      />
      <CustomizedDialogs open={this.state.dialogOpen} onClose={this.handleClose} condoItem={this.state.dialogRow}/>
    </div>
  );
}
}