import React, { Component } from "react";

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import Grid from '@mui/material/Grid';
import Tariffa from "./tariffa.component";

import SubCondoCalclist from "./subcondocalclist.component";
import SubCondoCalcedit from "./subcondocalcedit.component";

import condoService from "../services/condo.service";

export default class SubCalc extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentCond: props.currentCond,
            condoSubList: props.condoSubList,
            utilizzoSet: props.utilizzoSet,
            pageType: props.pageType,
            showLM: props.showLM,
            showNM: false,
            showCT: false,
        };
        this.showNM = this.showNM.bind(this);
        this.showCT = this.showCT.bind(this);
        this.CloseNM = this.CloseNM.bind(this);
        this.CloseCT = this.CloseCT.bind(this);
        this.sendSMS = this.sendSMS.bind(this);
        this.handler = this.handler.bind(this);
    }

    handler() {
        condoService.getSub(this.state.currentCond.id).then(
            (responseCond) => {
                this.setState({
                    condoSubList: responseCond
                });
                this.CloseNM()
                this.CloseCT()
            });
            this.props.handlerRefresh();
    }

    componentDidUpdate(propsPrec) {
        if (this.props.condoSubList !== propsPrec.condoSubList) {
            this.setState({
                currentCond: this.props.currentCond,
                condoSubList: this.props.condoSubList,
            });
        }
    }

    showNM() {
        this.setState({
            showNM: true
        });
    }
    showCT() {
        this.setState({
            showCT: true
        });
    }

    CloseNM() {
        this.setState({
            showNM: false
        });
    }

    CloseCT() {
        this.setState({
            showCT: false
        });
    }

    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    async sendSMS() {
        let tmpCondo = this.state.condoSubList.map(el => (Object.assign({}, el, { send: "sending" })))
        this.setState({
            condoSubList: []
        });
        this.setState({
            condoSubList: tmpCondo
        });
        await this.sleep(2000);
        tmpCondo = this.state.condoSubList.map(el => (el.telefono ? Object.assign({}, el, { send: "send" }) : Object.assign({}, el, { send: "error" })))
        this.setState({
            condoSubList: []
        });
        this.setState({
            condoSubList: tmpCondo
        });
    }

    render() {
        return (
            <div>
                <div>
                    <Box>
                        <Grid container sx={{ mt: 5 }}>
                            <Grid item xs={6}>
                                <Typography component="h2" variant="h5" >
                                    Ncf - Condòmini Generici
                                </Typography>
                            </Grid>
                            {
                                (this.state.showCT) ?
                                    <Grid item xs={12}
                                        sx={{
                                            boxShadow: 1,
                                            border: 0,
                                            borderColor: 'primary.light',
                                            '& .MuiDataGrid-cell:hover': {
                                                color: 'primary.main',
                                            },
                                            p: 1
                                        }}
                                        style={{ backgroundColor: "white" }}>
                                        <Typography variant="body1">
                                            Calcolo Tariffe sul importo 
                                            <IconButton aria-label="delete" onClick={this.CloseCT}>
                                                <CloseIcon fontSize="small" />
                                            </IconButton>
                                        </Typography>
                                        <Tariffa currentCond={this.state.currentCond.id} handler={this.handler} handlerRefresh={this.props.handlerRefresh} subCalc={true}/>
                                    </Grid>
                                    :
                                    <Grid item xs={6}>
                                        <Stack direction="row" justifyContent="end">
                                            <Button variant="contained" color="success" size="large" float="right" sx={{ mb: 1 }}
                                                endIcon={<AddIcon />}
                                                onClick={this.showCT}
                                            >
                                                Ripartizione tariffaria
                                            </Button>
                                        </Stack>
                                    </Grid>
                            }
                        </Grid>
                    </Box>
                    <SubCondoCalclist handler={this.handler} rows={this.state.condoSubList} />
                    {
                        (this.state.showNM) ?
                            <div>
                                <Typography component="h2" variant="h5" sx={{ mt: 3 }}>
                                    Nuovo Modulo
                                    <IconButton aria-label="delete" onClick={this.CloseNM}>
                                        <CloseIcon fontSize="small" />
                                    </IconButton>
                                </Typography>
                                <SubCondoCalcedit handler={this.handler} item={this.state.currentCond.id} utilizzoSet={this.state.utilizzoSet} />
                            </div>
                            :
                            <Box sx={{ mt: 2 }}>
                                <Stack direction="row" justifyContent="end">
                                    <Button variant="contained" color="primary" size="large" float="right" sx={{ mr: 1 }}
                                        endIcon={<AddIcon />}
                                        onClick={this.showNM}
                                    >
                                        Nuovo Nucleo abitativo
                                    </Button>
                                    {(this.state.pageType === "simple")?
                                    ""
                                    :
                                    <Button variant="contained" color="primary" size="large" float="right" component="label"
                                        endIcon={<AddIcon />}
                                    >
                                        Importazione Massiva
                                        <input
                                            type="file"
                                            hidden
                                        />
                                    </Button>
                                    }
                                </Stack>
                            </Box>
                    }

                </div>
            </div>
        )
    }
}